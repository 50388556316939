<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>

    <page-container
        ref="pageContainer"
        class="integral-goods-container nav-page-container"
        :class="{'is-column-model': viewModel === 'column'}"
        :scroll-top.sync="scrollTop"
    >
      <van-grid class="integral-href-container" column-num="2">
        <van-grid-item icon="service-o" text="积分售后" @click="integralAfterSaleList"/>
        <van-grid-item icon="orders-o" text="积分订单" @click="integralOrderList"/>
      </van-grid>
      <!-- 固钉 -->
      <affix-item
          ref="affix"
          style="position: relative;z-index: 10"
          :target="() => $refs.pageContainer.$el"
          @change="flag => this.affixIsTop = flag"
      >
        <!-- 分类搜索 -->
        <div class="goods-search-container" :class="{ affixIsTop }">
          <van-search
              v-model.trim="searchName"
              shape="round"
              show-action
              placeholder="请输入搜索关键词"
              @search="handleNameSearch"
          >
            <template #action>
              <div class="search-btn" @click="handleNameSearch">搜索</div>
            </template>
          </van-search>
        </div>
      </affix-item>
      <!-- 下拉刷新 -->
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="position: relative;z-index: 1"
      >
        <!-- 更多列表加载 -->
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <template #loading>
            {{ pagination.pageNum < 2 ? '' : '加载中...' }}
          </template>

          <goods-info
              v-for="item in dataList"
              :view-model="viewModel"
              :key="item.id"
              @click="handleGoodsClick(item)"
              :value="{
              goodsImgSrc: getFirstImg(item),
              goodsName: item.name,
              goodsDesc: '',
              goodsPrice: item.integralUsed,
            }"
          >
            <template #price>
              <div v-html="getPriceAndIntegralText(item)"></div>
            </template>
          </goods-info>
        </van-list>
      </van-pull-refresh>
    </page-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AffixItem from '@/components/Affix';
import GoodsInfo from '@/components/GoodsInfo';
import { integralGoodsPageGoods } from '@/api/integralGoods';
import { objectGetter } from '@/util';

export default {
  name: 'integralGoods',
  components: { AffixItem, GoodsInfo },
  data() {
    return {
      viewModel: 'row', //column
      affixIsTop: false,

      searchName: '', // 商品名input

      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.integralGoods.refreshing,
    }),
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;

      this.scrollTop = 0;
      this.$store.commit('integralGoods/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.pageContainer.scrollToTop(this.scrollTop);
      this.$refs.affix.updatePosition();
    });
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchName: name,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return integralGoodsPageGoods({
        name,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    async onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    getFirstImg(goods) {
      return objectGetter(goods, 'imageList.0.url');
    },
    getPriceAndIntegralText(goods) {
      let { goodsPrice, integralUsed } = goods || {};
      let priceHtml = goodsPrice ? [`<span class="integral-price">${goodsPrice}</span>`] : [];
      let integralUsedHtml = integralUsed ? [`<span class="integral">${integralUsed}</span>`] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    handleNameSearch() {
      this.name = this.searchName;
      this.onRefresh();
    },
    handleGoodsClick(goods) {
      let { id: integralGoodsId } = goods;
      this.$router.push({
        path: '/integralGoodsDetail',
        query: { integralGoodsId },
      });
    },

    // 积分订单
    integralOrderList() {
      this.$store.commit('integralOrderList/setRefreshing', true);
      this.$router.push('/integralOrderList');
    },
    // 积分售后申请列表
    integralAfterSaleList() {
      this.$store.commit('integralAfterSaleList/setRefreshing', true);
      this.$router.push('/integralAfterSaleList');
    },

  },
};
</script>

<style lang="less" scoped>
@bg-color: #f6f6f6;
.integral-goods-container {
  //padding-top: 84px;
  //background: url("../../img/categories/topBackground.png") top center no-repeat #fff;
  //background-size: 100% auto;

  .integral-href-container {
    margin: 14px;
    border-radius: 20px;
    overflow: hidden;

    /deep/ .van-grid-item__text {
      font-size: 14px;
    }
  }

  .goods-search-container {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    overflow: hidden;
    transition: border-radius 1s;

    &.affixIsTop {
      border-radius: 0;
    }
  }

  .van-pull-refresh {
    background-color: #fff;
  }

  .van-list {
    padding: 10px 10px 0 10px;
  }


  &.is-column-model {
    background-color: @bg-color;

    .van-icon.van-icon-apps-o {
      color: @primary-color;
    }

    .van-pull-refresh {
      background-color: @bg-color;
    }

    .van-list {
      padding: 10px 10px 0 10px;
    }

  }

  .van-pull-refresh {
    min-height: calc(100% - 54px);
  }

  /deep/ .integral-price {
    font-size: 18px;
    font-weight: bolder;
    color: #e93b3d;
    white-space: nowrap;
    overflow-x: auto;

    &:before {
      content: '¥ ';
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .integral {
    font-size: 18px;
    font-weight: bolder;
    color: #ffa800;
    white-space: nowrap;
    overflow-x: auto;

    &:after {
      content: '积分';
      margin-left: 3px;
      font-size: 12px;
      font-weight: normal;
    }
  }


}
</style>
